<template>
	<div class="grid mb-4">
		<div class="title">{{ isNew ? 'Создание разрешения на добычу' : 'Разрешение на добычу' }}</div>
		<form @submit.prevent>
			<BaseGroup class="block" title="Основные данные">
				<BaseInput v-model="license.number" placeholder="Номер лицензии" :disabled="!isAdmin" :required="isAdmin"/>
				<BaseInput v-model="license.signedBy" placeholder="Регистратор" :disabled="!isAdmin"/>
				<BaseInput v-model="license.reason" placeholder="Основание выдачи" :disabled="!isAdmin"/>
				<BaseCombo
					placeholder="Участок"
					addElementEndpoint="/dictionary/fisherRegion"
					:options="fisherRegionsOptions"
					@click="searchFisherRegion('')"
					@update:search="searchFisherRegion"
					@update:value="selectFisherRegion"
					v-model:search="fisherRegionsSearchValue"
				/>
				<div class="grid grid--2">
					<BaseInput
						v-model="license.dateStart"
						type="date"
						placeholder="Дата выдачи"
						:disabled="!isAdmin"
						:required="isAdmin"
						@change="onChangeLicenseDate"
					/>
					<BaseInput
						v-model="license.dateEnd"
						type="date"
						placeholder="Действует до"
						:disabled="!isAdmin"
						:required="isAdmin"
						@blur="onChangeLicenseDate"
					/>
				</div>
			</BaseGroup>
			<BaseGroup class="block license-quotas" title="Квоты" :count="quotasMap.length">
				<div class="grid grid--2">
					<ListShips :map='quotasMap' :isAdmin='isAdmin' @show='showModalQuota' @delete="deleteQuota"/>
				</div>
				<div class="grid grid--3" v-if='isAdmin'>
					<BaseSelect
						ref="fishSelectElem"
						placeholder="Выберите вид рыбы"
						:options="fishes"
						@update:option="selectFish"
					/>
					<BaseInput
						v-model="newQuota.volume"
						@update:modelValue="newQuota.volume = +newQuota.volume"
						placeholder="Количество, в кг."
						:decimalNumber="true"
					/>
					<BaseButton :disabled="!newQuota.volume || !newQuota.fish.id" @click="addQuota"> Добавить</BaseButton>
				</div>
			</BaseGroup>

			<BaseGroup class="block license-methods" title="Периоды орудий вылова"
						:count="license.fishingMethods?.length">
				<div class="grid grid--2" v-if="!!license.fishingMethods">
					<ListMethods :map='license.fishingMethods' :isAdmin='isAdmin' @delete="deleteMethod"/>
				</div>
				<div class="grid grid--4" v-if='isAdmin'>
					<BaseSelect
						ref="fishSelectElem"
						placeholder="Выберите метод ловли"
						:options="methods"
						v-model:option="method.fishingMethod.id"
					/>
					<BaseInput v-model="method.dateStart" type="date" placeholder="от" @change="onChangeMethodDate"/>
					<BaseInput v-model="method.dateEnd" type="date" placeholder="до" @blur="onChangeMethodDate"/>
					<BaseButton @click="addMethod"
								:disabled="!method.fishingMethod || !method.dateStart || !method.dateEnd">
						Добавить
					</BaseButton>
				</div>
			</BaseGroup>
			<BaseButton v-if='isAdmin' @click="addLicense" :disabled="formIsInvalid"> Сохранить</BaseButton>
			<BaseButton v-if='isAdmin && !isNew' view="danger" @click="delLicense" class="button-delete"> Удалить
			</BaseButton>

			<BaseButton v-if='!isNew' class="license-report" @click='showReportModal'> Отчет по использованию квот
			</BaseButton>

			<TheModal v-model:show='modals.quotas.state'>
				<BaseGroup title='Заполните данные о квоте'>
					<BaseInput v-model='license.quotas[modals.quotas.id].fish.name' disabled placeholder='Вид рыбы'/>
					<BaseInput v-model='license.quotas[modals.quotas.id].volume' placeholder='Количество, в кг.'/>
					<BaseButton @click='closeModalQuota'>Сохранить</BaseButton>
				</BaseGroup>
			</TheModal>

			<TheModal v-model:show='modals.report.state'>
				<BaseGroup title='Выберите период за который необходим отчёт'>
					<BaseInput v-if="!modals.report.forAll" v-model="modals.report.dateStart" type="date"
								placeholder="от"/>
					<BaseInput v-if="!modals.report.forAll" v-model="modals.report.dateEnd" type="date"
								placeholder="до"/>
					<div class="flex">
						<input id="report-for-all" v-model="modals.report.forAll" type="checkbox" class="mr-2">
						<label for="report-for-all">За весь период лицензии</label>
					</div>
					<BaseButton @click='downloadReport'
								:disabled="isReportDownloadDisable">
						Получить отчёт
					</BaseButton>
				</BaseGroup>
			</TheModal>
		</form>
	</div>
</template>

<script>
import axios from 'axios'

import { computed, ref, onMounted, watch, nextTick } from 'vue'
import { useApi } from '@/modules/api'
import { useRoute, useRouter } from 'vue-router'

import BaseGroup from '@/components/ui/BaseGroup'
import BaseInput from '@/components/ui/BaseInput'
import BaseButton from '@/components/ui/BaseButton'
import BaseSelect from '@/components/ui/BaseSelect'
import BaseCombo from '@/components/ui/BaseCombo'
import ListShips from '@/components/lists/ListShips'
import ListMethods from '@/components/lists/ListMethods'
import TheModal from '@/components/TheModal'

import { useAlert } from '@/modules/composition/useAlert'
import toTimestamp from '@/utils/toTimestamp'

export default {
	name: 'LicenseNew',
	components: {
		BaseInput,
		BaseGroup,
		BaseButton,
		BaseSelect,
		BaseCombo,
		ListShips,
		ListMethods,
		TheModal
	},
	setup () {
		const route = useRoute()
		const router = useRouter()

		const isNew = route.name === 'LicenseNew'
		const isAdmin = JSON.parse(localStorage.account).isAdmin
		const isReportDownloadDisable = computed(() => {
			return !modals.value.report.forAll &&
				(!modals.value.report.dateEnd ||
				!modals.value.report.dateStart ||
				new Date(modals.value.report.dateStart) > new Date(modals.value.report.dateEnd))
		})

		const license = ref({
			number: null,
			signedBy: null,
			reason: null,
			fisherRegion: {
				id: null,
				name: null
			},
			fishingMethods: [],
			dateOfIssue: Date.now(),
			dateStart: Date.now(),
			dateEnd: Date.now(),
			quotas: []
		})

		const modals = ref({
			quotas: {
				state: false,
				id: null
			},
			report: {
				state: false,
				forAll: false,
				dateStart: null,
				dateEnd: null
			}
		})

		const fisherRegionsOptions = ref({})
		const fisherRegionsSearchValue = ref('')

		const fishes = ref({})
		const fishesc1Id = ref({})
		const formIsInvalid = ref(true)

		watch(license, async () => {
			await nextTick()
			formIsInvalid.value = document.querySelectorAll(':invalid').length > 0
		},
		{ deep: true }
		)

		const selectFisherRegion = (id) => {
			if (id) {
				license.value.fisherRegion = {
					id: +id,
					name: fisherRegionsOptions.value[+id]
				}
			} else {
				license.value.fisherRegion = {
					id: null,
					name: null
				}
			}
		}

		const searchFisherRegion = async (value) => {
			const { get, data } = useApi(`dictionary/fisherRegion/search?limit=5&search=${value}`)
			await get()
			fisherRegionsOptions.value = {}
			data.value.forEach(el => {
				fisherRegionsOptions.value[el.id] = el.name
			})
		}
		const fishSelectElem = ref(null)

		const selectFish = (id) => {
			if (id) {
				const isAdded = license.value.quotas.find(el => el.fish.id === +id)
				if (isAdded) {
					const { errorAlert } = useAlert()
					errorAlert('Квота для этого вида рыбы уже добавлена')
					fishSelectElem.value.clear()
				} else {
					newQuota.value = {
						...newQuota.value,
						fish: {
							id: +id,
							name: fishes.value[+id],
							c1Id: fishesc1Id.value[+id]
						}
					}
				}
			} else {
				newQuota.value = {
					...newQuota.value,
					fish: {
						id: null,
						name: null,
						c1Id: null
					}
				}
			}
		}

		const methods = ref({})
		const method = ref({
			dateStart: null,
			dateEnd: null,
			fishingMethod: {
				id: null,
				name: null
			}
		})

		const onChangeMethodDate = () => {
			if (method.value.dateStart > method.value.dateEnd) {
				method.value.dateEnd = null
			}
		}

		const onChangeLicenseDate = () => {
			if (license.value.dateStart > license.value.dateEnd) {
				license.value.dateEnd = null
			}
		}

		const getMethods = async () => {
			const { get, data } = useApi('/dictionary/fishingMethod')

			await get()
			data.value.forEach(item => {
				methods.value[item.id] = item.name
			})
		}

		const addMethod = async () => {
			method.value.dateStart = toTimestamp(method.value.dateStart)
			method.value.dateEnd = toTimestamp(method.value.dateEnd)
			method.value.fishingMethod.name = methods.value[method.value.fishingMethod.id]
			license.value.fishingMethods.push(method.value)
			method.value = {
				dateStart: null,
				dateEnd: null,
				fishingMethod: {
					id: null,
					name: null
				}
			}
		}

		const deleteMethod = (index) => {
			license.value.fishingMethods.splice(index, 1)
		}

		const newQuota = ref({
			volume: null,
			fish: {
				id: null,
				name: null,
				c1Id: null
			}
		})
		const quotasMap = ref([])

		const mappingQuotas = (quotasMap, license) => {
			quotasMap.value = []
			license.value.quotas.forEach(el => {
				quotasMap.value.push({
					'Вид рыбы': el.fish.name,
					Количество: el.volume + ' кг'
				})
			})
		}

		const addQuota = () => {
			license.value.quotas.push(newQuota.value)
			mappingQuotas(quotasMap, license)
			newQuota.value = {
				volume: null,
				fish: {
					id: null,
					name: null,
					c1Id: null
				}
			}
			fishSelectElem.value.clear()
		}

		const deleteQuota = (id) => {
			license.value.quotas.splice(id, 1)
			mappingQuotas(quotasMap, license)
		}

		const showModalQuota = (id) => {
			modals.value.quotas.id = id
			modals.value.quotas.state = true
		}

		const closeModalQuota = () => {
			modals.value.quotas.id = null
			modals.value.quotas.state = false
			mappingQuotas(quotasMap, license)
		}

		const formatDate = (unixTimeStamp) => {
			const options = {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric'
			}
			const date = new Date(unixTimeStamp * 1000)
			return date.toLocaleString('af', options)
		}

		const formattingDates = () => {
			license.value.dateStart = formatDate(license.value.dateStart)
			license.value.dateEnd = formatDate(license.value.dateEnd)
		}

		const addLicense = async () => {
			const { post } = useApi('/fishingLicense')

			license.value.dateStart = toTimestamp(license.value.dateStart)
			license.value.dateEnd = toTimestamp(license.value.dateEnd)

			await post(license.value)
			router.push('/licenses')
		}

		const delLicense = async () => {
			const { del } = useApi(`/fishingLicense/${route.params.id}`)
			await del()
			router.push('/licenses')
		}

		const showReportModal = () => {
			modals.value.report.state = true
		}

		const downloadReport = async () => {
			const api = axios.create({
				baseURL: window.location.origin
			})
			let params
			let url
			if (modals.value.report.forAll) {
				url = `/api/fishingLicense/${route.params.id}/usageReportFtl`
				params = {}
			} else {
				url = '/api/getReport/Actionslicenses'
				params = {
					id: route.params.id,
					startDate: modals.value.report.dateStart,
					endDate: modals.value.report.dateEnd
				}
			}

			const { data } = await api.get(url, {
				headers: {
					Authorization: window.localStorage.getItem('access_token')
				},
				responseType: 'arraybuffer',
				params
			})

			const blob = new Blob([data], { type: 'application/pdf' })
			const link = document.createElement('a')
			link.href = window.URL.createObjectURL(blob)
			link.download = `FishingLicenseUsageReport-${license.value.number}`
			link.click()
		}

		onMounted(async () => {
			if (!isNew) {
				const { get, data } = useApi(`/fishingLicense/${route.params.id}`)

				await get()

				license.value = data.value
				fisherRegionsSearchValue.value = license.value.fisherRegion.name

				mappingQuotas(quotasMap, license)
				formattingDates()

				// isLoaded.value = true
			}

			if (isAdmin) {
				const fishApi = useApi('/dictionary/fish')

				await fishApi.get()
				fishApi.data.value.forEach(el => {
					fishes.value[el.id] = el.name
					fishesc1Id.value[el.id] = el.c1Id
				})

				await getMethods()
			}
		})

		return {
			isNew,
			isAdmin,
			license,
			modals,
			fisherRegionsOptions,
			fisherRegionsSearchValue,
			selectFisherRegion,
			searchFisherRegion,
			fishes,
			selectFish,
			fishSelectElem,
			methods,
			method,
			onChangeMethodDate,
			onChangeLicenseDate,
			addMethod,
			deleteMethod,
			newQuota,
			quotasMap,
			addQuota,
			deleteQuota,
			showModalQuota,
			closeModalQuota,
			addLicense,
			delLicense,
			showReportModal,
			downloadReport,
			isReportDownloadDisable,
			formIsInvalid
		}
	}
}
</script>

<style lang="scss" scoped>
.block {
	margin-bottom: 20px;
}
.license-quotas {
	button {
		width: 120px;
	}
}

.button-delete {
	margin-left: 10px;
}

.license-report {
	position: absolute;
	top: 60px;
	right: 20px;
}

.ship {
	.button.default.mb-2 {
		display: none;
	}
}
</style>
