const toDigits = (value) => {
	return value.toString().padStart(2, '0')
}

export default timestamp => {
	const date = new Date(timestamp)
	const dateString = `${toDigits(date.getUTCDate())}-${toDigits(date.getUTCMonth() + 1)}-${date.getUTCFullYear()}`
	const timeString = `${toDigits(date.getHours())}:${toDigits(date.getMinutes())}`

	return `${dateString} ${timeString}`
}
